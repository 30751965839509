.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-transform-none
{
  text-transform: none !important;
}

.line-height-1 {
  line-height: 1;
}

.bg-stripper-white {
  background-image: $stripped-white !important;
  background-size: 5.00px 5.00px !important;
}

.bg-stripper-black {
  background-image: $stripped-black !important;
  background-size: 5.00px 5.00px !important;
}

.bg-cover {
  background: transparent center center no-repeat;
  background-size: cover;
}
