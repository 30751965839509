// Color system

$primary: #000;
$secondary: #fff;

// Options

$enable-caret: false;
$enable-rounded: false;
$enable-print-styles: false;
$enable-responsive-font-sizes: true;

// Spacing

$spacer: 1rem;
$spacers: (
  5: ($spacer * 2),
  6: ($spacer * 2.5),
  7: ($spacer * 3),
  8: ($spacer * 3.5),
  9: ($spacer * 4),
  10: ($spacer * 4.5),
);

// Body

$body-bg: #ffffff;
$body-color: #000000;

// Paragraphs

$paragraph-margin-bottom: .5rem;

// Components

$line-height-xs: 1.5;
$line-height-xl: 1.5;
$line-height-xxl: 1.75;

$border-radius-xs: .20rem;
$border-radius-xl: .35rem;
$border-radius-xxl: .4rem;

// Typography

$font-family-sans-serif: "Aeonik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-coanda: "Coanda", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-awesome: "Font Awesome 5 Free";

$font-size-base: 1rem;
$font-size-xs: $font-size-base * .75;
$font-size-xl: $font-size-base * 1.5;
$font-size-xxl: $font-size-base * 1.75;

$headings-font-weight: 600;

$font-sizes: (
  10: 10px,
  12: 12px,
  14: 14px,
  18: 18px,
  20: 20px,
  24: 24px,
  26: 26px,
  30: 30px,
  32: 32px,
  36: 36px,
  40: 40px,
  42: 42px,
  44: 44px,
  46: 46px,
  48: 48px,
  50: 50px,
  60: 60px,
  90: 90px,
  146: 146px,
);

// SCSS RFS mixin

$rfs-base-font-size: 1rem;

// Buttons

$btn-font-weight: 700;

$btn-padding-y-xs: .125rem;
$btn-padding-x-xs: .25rem;
$btn-font-size-xs: $font-size-xs;
$btn-line-height-xs: $line-height-xs;

$btn-padding-y-xl: .75rem;
$btn-padding-x-xl: 1.5rem;
$btn-font-size-xl: $font-size-xl;
$btn-line-height-xl: $line-height-xl;

$btn-padding-y-xxl: 1rem;
$btn-padding-x-xxl: 2rem;
$btn-font-size-xxl: $font-size-xxl;
$btn-line-height-xxl: $line-height-xxl;

$btn-border-radius-xs: $border-radius-xs;
$btn-border-radius-xl: $border-radius-xl;
$btn-border-radius-xxl: $border-radius-xxl;

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out;

// Navbar

$navbar-dark-color: $secondary;
$navbar-dark-hover-color: $secondary;
$navbar-dark-active-color: $secondary;

$nav-item-width: 96px;

// Dropdowns

$dropdown-min-width: 100%;
$dropdown-spacer: 0;
$dropdown-font-size: 18px;
$dropdown-border-width: 0;
$dropdown-box-shadow: 3px 3px 6px rgba(#000, .175);

$dropdown-link-color: fade_out($primary, .4);
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: transparent;

$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: transparent;

$dropdown-item-padding-x: .75rem;

$dropdown-item-progress-height: .5rem;
$dropdown-item-progress-transition-duration: .15s;

// animations duration
$sidenav-transition-duration: .3s;
$sidelink-transition-duration: .6s;
$sidelink-width: 40px;
$sidelink-height: 300px;
$sidelink-label-height: $sidelink-height - $sidelink-width;

// Data images

//$stripped-black: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQYV2NkwAT/GdHE/jMwMDAiC4IFQIpggnABmCCKAEgQAABOBQUaykC8AAAAAElFTkSuQmCC";
$stripped-black: linear-gradient(135deg, #000000 10%, #ffffff 10%, #ffffff 50%, #000000 50%, #000000 60%, #ffffff 60%, #ffffff 100%);
//$stripped-white: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAIklEQVQYV2NkYGD4z4AKGBnRBMF8ZEE4G8ZA0QHigACKuQCw8QgCk6qj2gAAAABJRU5ErkJggg==";
$stripped-white: linear-gradient(135deg, #ffffff 10%, #000000 10%, #000000 50%, #ffffff 50%, #ffffff 60%, #000000 60%, #000000 100%);

// lightgallery
$lg-toolbar-bg: rgba(255,255,255, 0.45);
$lg-theme: #d6d6d6;
$lg-icon-bg: rgba(255,255,255, 0.45) !default;
$lg-icon-color: #606060 !default;
$lg-icon-hover-color: #000000 !default;
$lg-counter-color: #191919 !default;
$lg-progress-bar-bg: #c9c9c9 !default;
$lg-sub-html-bg: rgba(255,255,255, 0.45) !default;
$lg-sub-html-color: #111111 !default;

// z-index onglets menu
// boucle créé dans le fichier '_index.scss'

$z-index: (
  0: 0,
  1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10,
  11: 11, 12: 12, 13: 13, 14: 14, 15: 15, 16: 16, 17: 17, 18: 18, 19: 19, 20: 20,
  21: 21, 22: 22, 23: 23, 24: 24, 25: 25, 26: 26, 27: 27, 28: 28, 29: 29, 30: 30,
);
