@function pxToRem($px) {
  $rem: $px / 16px * 1rem;
  @return $rem;
}

@mixin font-awesome-fas($size: 16px) {
  font-weight: 900;
  font-family: $font-family-awesome;
  font-size: pxToRem($px: $size);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@mixin button-fab($padding, $font-size, $line-height) {
  $width: ($padding * 2) + (pxToRem($px: 1px) * 2) + ($font-size * $line-height);
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  min-width: $width;
  height: $width;
  min-height: $width;
  padding: $padding;
  @include font-size($font-size);
  line-height: $line-height;
  border-radius: 200px;
}

@mixin button-fab-size($padding, $font-size, $line-height) {
  $width: ($padding * 2) + (pxToRem($px: 1px) * 2) + ($font-size * $line-height);
  width: $width;
  min-width: $width;
  height: $width;
  min-height: $width;
  padding: $padding;
  @include font-size($font-size);
  line-height: $line-height;
}
