.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

.btn-xl {
  @include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-line-height-xl, $btn-border-radius-xl);
}

.btn-xxl {
  @include button-size($btn-padding-y-xxl, $btn-padding-x-xxl, $btn-font-size-xxl, $btn-line-height-xxl, $btn-border-radius-xxl);
}

.btn {
  text-transform: uppercase;

  &.btn-fab {
    @include button-fab($btn-padding-y, $btn-font-size, $btn-line-height);

    &:not(.collapsed) {
      transform: rotateZ(180deg);
    }
  }
}

.btn-fab {
  &.btn-xxl {
    @include button-fab-size($btn-padding-y-xxl, $btn-font-size-xxl, $btn-line-height-xxl);
  }

  &.btn-xl {
    @include button-fab-size($btn-padding-y-xl, $btn-font-size-xl, $btn-line-height-xl);
  }

  &.btn-lg {
    @include button-fab-size($btn-padding-y-lg, $btn-font-size-lg, $btn-line-height-lg);
  }

  &.btn-sm {
    @include button-fab-size($btn-padding-y-sm, $btn-font-size-sm, $btn-line-height-sm);
  }

  &.btn-xs {
    @include button-fab-size($btn-padding-y-xs, $btn-font-size-xs, $btn-line-height-xs);
  }
}
