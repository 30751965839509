@each $size, $value in $font-sizes {
  .fz-#{$size} {
    @include font-size($size)
  }
}

@each $size, $value in $font-sizes {
  .lh-#{$size} {
    line-height: pxToRem($value);
  }
}

.font-family-coanda {
  font-family: $font-family-coanda;
}

.font-family-aeonik {
  font-family: $font-family-sans-serif;
}
